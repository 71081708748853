.members-page * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.members-page {
    position: relative;
    width: 100%;
    background-color: #fff;
    min-height: 700px;
    overflow: hidden;
}

.members-page .input-field, .forgot-password .input-field {
    max-width: 380px;
    width: 100%;
    height: 50px;
    background-color: #f0f0f0;
    margin: 10px 0;
    border-radius: 30px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
}

.members-page .input-field.address {
    height: 120px;
    border-radius: 20px;
}

.members-page .input-field i, .forgot-password .input-field i {
    text-align: center;
    line-height: 50px;
    margin-left: 10%;
}

.members-page .input-field input,
.forgot-password .input-field input,
.members-page .input-field select {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
    width: 95%
}

.members-page .input-field input::placeholder,
.forgot-password .input-field input::placeholder,
.members-page .input-field select:not(.selected) {
    color: #aaa;
    font-weight: 500;
}

.members-page .input-field select {
    margin-left: -4px;
}

.members-page .btn, .forgot-password .btn {
    width: 150px;
    height: 49px;
    border: none;
    outline: none;
    border-radius: 48px;
    cursor: pointer;
    background-color: #a9d5a7;
    color: #fff;
    font-weight: 600;
    margin: 10px 0;
    transition: all 0.5s;
}

.members-page .sign-up-form .btn {
    background-color: #63b261;
}

.members-page .btn:hover, .forgot-password .btn:hover {
    background-color: green;
    color: white;
}

.members-page .social-media button {
    justify-content: center;
    width: fit-content;
}


.members-page.sign-up-mode form.sign-in-form {
    z-index: 1;
    opacity: 0;
}

.members-page.sign-up-mode form.sign-up-form {
    z-index: 2;
    opacity: 1;
}

.members-page .panel h3 {
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
}

.members-page .panel p {
    font-size: 0.95rem;
    padding: 0.7rem 0;
}

.members-page .forms-container {
    width: 100%;
}

.forgot-password .forms-container {
    width: 340px;
    margin: 0 auto;
    text-align: center;
}

.forgot-password .forms-container h2 {
    margin: auto;
}

.members-page form.sign-in-form {
    z-index: 2;
}

.members-page form.sign-up-form {
    z-index: 1;
    opacity: 0;
}

.members-page .forms-container h2 {
    margin: 0 auto;
}

@media screen and (max-width: 700px) {
    #members-section-h1, #members-section-h2 {
        font-size: 100% !important;
    }

    .members-page {
        overflow: visible;
        transition: 0.3s 0.3s ease-in-out;
    }

    .members-page form {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 1rem;
        grid-column: 1 / 1;
        grid-row: 1 / 2;
        transition: 0.1s 0.3s ease-in-out;
    }

    .members-page .signin-signup {
        width: 100%;
        position: absolute;
        display: grid;
        grid-template-columns: 1fr;
        z-index: 5;
        transition: 0.4s 0.2s ease-in-out;
    }

    .members-page .input-field {
        max-width: available;
        width: 100%;
        height: 50px;
        background-color: #f0f0f0;
        margin: 10px 0;
        border-radius: 30px;
        display: grid;
        grid-template-columns: 15% 85%;
        padding: 0 0.4rem;
        position: relative;
    }

    .members-page .left-panel {
        float: left;
        margin-left: 20px;
    }

    .members-page .right-panel {
        float: right;
        margin-right: 20px;
    }

    .members-page .forms-container {
        clear: both;
    }

    .members-page .panel .content p, .members-page .panel .content h3 {
        display: none;
    }

    .members-page.sign-up-mode {
        min-height: 680px;
    }

    .members-page:not(.sign-up-mode) {
        min-height: 400px;
    }
}


@media screen and (min-width: 701px) {
    .members-page:before {
        content: '';
        position: absolute;
        min-height: 100vh;
        width: 75%;
        top: 0;
        right: 48%;
        background-image: linear-gradient(-45deg, #dcefd9 0%, green 100%);
        transition: 0.9s ease-in-out;
        border-radius: 50px;
        z-index: 6;
    }

    .members-page .panels-container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .members-page .panel {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: center;
        z-index: 7;
    }

    .members-page .left-panel {
        pointer-events: all;
        padding: 3rem 17% 2rem 12%;
    }

    .members-page .right-panel {
        pointer-events: none;
        padding: 3rem 24% 2rem 17%;
    }

    .members-page .panel .content {
        color: #fff;
        transition: 0.45s 0.3s ease-in-out;
        margin-top: 60px;
    }

    .members-page .forms-container {
        position: absolute;
        top: 0;
        left: 0;
    }

    .members-page form {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 5rem;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        transition: 0.1s 0.35s ease-in-out;
    }

    .members-page .signin-signup {
        position: absolute;
        top: 0;
        transform: translate(-50%, 0);
        left: 75%;
        width: 50%;
        height: 40%;
        display: grid;
        grid-template-columns: 1fr;
        z-index: 5;
        transition: 0.25s 0.15s ease-in-out;
    }

    .members-page .right-panel .content {
        transform: translateX(800px);
    }

    /* ANIMATION */
    .members-page.sign-up-mode::before {
        transform: translate(100%, 0);
        right: 52%;
    }

    .members-page.sign-up-mode .left-panel .content {
        transform: translateX(-800px);
    }

    .members-page.sign-up-mode .left-panel {
        pointer-events: none;
    }

    .members-page.sign-up-mode .right-panel {
        pointer-events: all;
    }

    .members-page.sign-up-mode .right-panel .image,
    .members-page.sign-up-mode .right-panel .content {
        transform: translateX(0%);
    }

    .members-page.sign-up-mode .signin-signup {
        left: 25%;
    }
}

@media screen and (max-width: 400px) {
    .members-page .btn {
        width: 100px;
    }
}