* {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
}

.nav-small .text {
    margin-right: 10px;
}

body {
    background: #e4e1e0;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 150%;
    overflow-x: auto !important;
}

.home-title {
    text-align: center;
    color: #555555 !important;
    margin-top: 0;
    font-size: 50px !important;
}

.middle-block {
    background: #ffffff;
    padding: 10px 0 20px 0 !important;
    /*border-radius: 10px;*/
    margin-top: 0 !important;
    margin-bottom: 10px !important;
}

.middle-block.full, .parent-block.full {
    width: auto;
    margin: 0 20px 30px 20px !important;
}

h1:not(.wysiwyg-content h1) {
    text-align: center;
    color: #555555 !important;
}

h2:not(.wysiwyg-content h2) {
    margin-right: 140px;
}

@media screen and (max-width: 800px) {
    .home-title {
        font-size: 25px !important;
    }

    .middle-block {
        border-radius: 0;
        padding: 10px 5px 20px 5px !important;
    }
}


h3:not(.wysiwyg-content h3) {
    text-align: center;
    font-size: 180% !important;
    color: #555555 !important;
}

.container {
    /*margin-bottom: 30px;*/
}

.footer {
    color: #555555;
    margin: 0;
    padding: 0 0 20px 0;
    background: #e4e1e0;
    text-align: center;
    width: 100%;
}

.button {
    color: #ffffff;
    background: #2d7e48;
    padding: 10px 20px;
    line-height: 200%;
    border-radius: 4px;
    font-weight: bold;
}

.button:hover {
    background: #63b883;
}

.central {
    text-align: center;
}

.input-error input, .input-error .wysiwyg-editor {
    border-color: red !important;
}

section {
    margin-bottom: 40px;
}

.small-grey-text {
    font-size: 80%;
    color: #666666;
}

.logout {
    padding: 0 10px !important;
}

.logout button div {
    padding: 3px 5px !important;
    margin-right: 0 !important;
}

.logout button span {
    padding: 0 10px 0 0 !important;
    font-weight: normal !important;
}

.login_with_google {
    padding: 0 !important;
    font-size: inherit !important;
}

.login_with_google div {
    padding: 1px 5px !important;
    margin-right: 0 !important;
}

a {
    margin: 0 5px;
}

a:hover {
    text-decoration: underline;
}

.wysiwyg-toolbar {
    padding: 5px;
    margin: 0 0 5px 0;
    border: 1px solid #b9dee2;
    border-radius: 5px;
}

.wysiwyg-editor {
    border-radius: 5px;
    border: 1px dashed #999999;
    padding: 5px;
}

.coordinators th {
    background: palegoldenrod !important;
}

.coordinators tr {
    vertical-align: top;
}

.coordinators td:nth-child(1), .popup .content {
    white-space: pre-line;
}

.popup .content {
    width: 400px !important;
}

.image-zoom {
    transition: all .2s ease-in-out;
}

.image-zoom:hover {
    transform: scale(3) !important;
    z-index: 100;
}


.latestNews {
    border: 3px solid mediumvioletred !important;
    position: relative;
}

.latestNewsFlash {
    position: absolute;
    top: -5px;
    right: -10px;
    transform: rotate(10deg);
    background: mediumvioletred;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.deepLink {
    border: 4px solid green !important;
    background: palegoldenrod !important;
}

.members_admin td {
    white-space: nowrap;
}

.ptra-carousel {
    padding: 20px;
}

.ptra-carousel p {
    padding: 10px;
    color: white;
    background: black;
    margin: 0 0 35px 0
}

.ptra-carousel video {
    height: 850px;
}

.ui.container.full {
    width: auto;
    margin: 0 20px 30px 20px !important;
}

.mobile-banner {
    display: none;
}
.wide-banner {
    display: block;
}


@media only screen and (max-width: 700px) {
    .nav-small {
        display: block !important;
    }

    .nav-full {
        display: none !important;
    }

    .ui.container:not(.text) {
        margin: 0 0 30px 0 !important;
    }

    .ptra-carousel video {
        height: 350px;
    }

    .mobile-banner {
        display: block;
    }
    .wide-banner {
        display: none;
    }
}